body {
  margin: 0;
  font-family: lato, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modalStyle .ant-modal-confirm-title,
.modalStyle .ant-modal-confirm-content {
  font-size: 20px !important;
  color: #ba191a !important;
}

.modalStyle .ant-modal-confirm-title {
  text-align: center;
  line-height: 1.7;
}

.modalStyle .anticon {
  margin-top: 5px;
}

.modalStyle .ant-btn {
  font-size: 18px;
  height: 40px;
}

.ant-notification .ant-notification-notice-message,
.ant-notification .ant-notification-notice-description {
  font-size: 18px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: lato;
}
